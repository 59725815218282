<template>
  <div class="container">
    <van-cell-group inset :border="false">
      <van-field center :border="false" label-align="right" label-width="50" v-model="form.mobile" label="手机号" placeholder="请输入手机号" />
      <van-field center :border="false" label-align="right" label-width="50" v-model="form.code" label="验证码" placeholder="请输入验证码">
        <template #button>
          <van-button size="small" :disabled="sendCodeDisabled" color="var(--app-button-bg-color)" @click="sendCode()">{{ sendCodeBtnText }}</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <van-button block style="margin-top: 15px" color="var(--app-button-bg-color)" @click="updateMobile">{{ subBtnText }}</van-button>
  </div>
</template>
<script>
export default {
  name: 'BindMobile',
}
</script>
<script setup>
import { Form, Field, CellGroup, Button, Toast } from 'vant'
import { ref, onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { bindMobile } from '@/api/player'
import { sendMobileCode } from '@/api/sms'
import { redirectPage } from '@/utils/common'
const VanForm = Form
const VanField = Field
const VanCellGroup = CellGroup
const VanButton = Button
const store = useStore()
const router = useRouter()

onMounted(() => {
  store.dispatch('user/getInfo')
  store.dispatch('player/getInfo')
})

const playerExtend = computed(() => {
  return store.state.player.extend
})

const form = reactive({ mobile: playerExtend.value.mobile, code: '', type: playerExtend.value.mobile ? 'unbindMobile' : 'bindMobile' })
const subBtnText = ref(playerExtend.value.mobile ? '确认解绑' : '确认绑定')
const sendCodeBtnText = ref('发送验证码')
const sendCodeDisabled = ref(false)
let countDown = 60

const setTime = countDown => {
  if (countDown === 0) {
    sendCodeBtnText.value = '发送验证码'
    sendCodeDisabled.value = false
    return
  } else {
    sendCodeBtnText.value = '重新发送(' + countDown + ')'
    countDown--
  }
  setTimeout(() => {
    setTime(countDown)
  }, 1000)
}

const validate = (validateCode = false) => {
  let validated = true
  if (!form.mobile) {
    Toast('请输入手机号')
    validated = false
  }

  let reg = /^(1[1-9][0-9])\d{8}$/
  if (!reg.test(form.mobile)) {
    Toast('输入的手机号格式不正确')
    validated = false
  }

  if (validateCode && !form.code) {
    Toast('请输入验证码')
    validated = false
  }

  return validated
}

const sendCode = () => {
  if (!validate()) {
    return false
  }

  sendMobileCode({ toMobile: form.mobile, type: form.type }).then(res => {
    sendCodeDisabled.value = true
    setTime(countDown)
    Toast.success(res.message)
  })
}

const updateMobile = () => {
  if (!validate()) {
    return false
  }

  bindMobile(form).then(res => {
    store.commit('player/SET_PLAYER_EXTEND', res.data.playerExtend)
    Toast.success({
      message: res.message,
      forbidClick: true,
      onClose: () => {
        redirectPage()
      },
    })
  })
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
}

.send-code {
  width: auto;
  padding: 10px;
  flex-shrink: 0;
  margin: 0;
}
</style>
